<template>
  <div>
    <v-row dense no-gutters style="margin: 0px; paddin: 0px">
      <v-col cols="12" sm="3" md="3">
        <v-row dense no-gutters cols="12" style="padding: 0; margin: 0">
          <v-col cols="12" sm="3" md="2">
            <v-btn
              fab
              dark
              small
              color="indigo"
              :disabled="player"
              class="ma-1"
              @click="startTimer"
            >
              <v-icon> mdi-play </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              dense
              style="margin-top: 4px; margin-right: 2px; width: 90px"
              :items="years"
              v-model="currentYear"
              item-value="testedYear"
              item-text="testedYear"
              outlined
              @change="select_year"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="2">
            <v-btn
              fab
              dark
              small
              color="indigo"
              :disabled="!player"
              class="ma-1"
              @click="pauseTimer"
            >
              <v-icon> mdi-pause </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-select
          style="margin-right: 2px"
          :items="level_list"
          item-value="id"
          item-text="label"
          label="Corrosion Severity"
          v-model="severity"
          multiple
          clearable
          dense
          outlined
          return-object
          @change="search_change"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 1">
              <span>{{ item.label }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="text-grey text-caption align-self-center"
            >
              (+{{ severity.length - 1 }})
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-select
          style="margin-right: 2px"
          :items="tubulars_list"
          v-model="strings_cor"
          item-value="id"
          item-text="item"
          label="String"
          multiple
          dense
          clearable
          outlined
          @change="search_change"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 1">
              <span>{{ item.item }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="text-grey text-caption align-self-center"
            >
              (+{{ strings_cor.length - 1 }})
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-select
          style="margin-right: 2px"
          :items="formations"
          v-model="corrosion_formations"
          item-value="id"
          item-text="formationname"
          label="Formation"
          multiple
          dense
          clearable
          outlined
          @change="search_change"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 1">
              <span>{{ item.formationname }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="text-grey text-caption align-self-center"
            >
              (+{{ corrosion_formations.length - 1 }})
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-select
          style="margin-right: 2px"
          :items="welltypes"
          v-model="welltype"
          item-value="welltype_id"
          item-text="welltype"
          label="Well Type"
          multiple
          dense
          clearable
          outlined
          @change="search_change"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 1">
              <span>{{ item.welltype }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="text-grey text-caption align-self-center"
            >
              (+{{ welltype.length - 1 }})
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <GmapMap
      id="map"
      @zoom_changed="onZoomChanged($event)"
      @click="showByIndex = null"
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      :key="kmap"
      map-type-id="satellite"
      :options="{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }"
    >
      <GmapCluster>
        <GmapMarker
          :key="pin.id"
          v-for="pin in wells_srch"
          :position="pin"
          @click="rapport_click(pin)"
          @mouseout="showByIndex = null"
          @mouseover="showByIndex = pin"
          :icon="get_Icon(pin)"
        >
          <GmapInfoWindow
            :options="{
              pixelOffset: {
                width: -25,
                height: (pin.csgs + 1) * 26,
              },
            }"
          >
            <div :style="get_label(pin)">
              {{ pin.wellname }}
            </div>
          </GmapInfoWindow>
          <GmapInfoWindow
            :opened="showByIndex === pin"
            :options="{
              zIndex: 9999,
              pixelOffset: {
                width: -10,
                height: 12,
              },
              maxWidth: 320,
              maxHeight: 400,
            }"
          >
            <div
              style="
                z-index: 9999;
                font-weight: bold;
                color: #8ebf42;
                font-family: arial;
                background-color: white;
                margin: 20px;
              "
            >
              <div
                v-for="item in get_info(pin)"
                :key="item.id"
                v-html="item.test"
              ></div>
            </div>
          </GmapInfoWindow>
        </GmapMarker>
      </GmapCluster>
    </GmapMap>
    <div id="map_canvas">
      <div id="dsearch2" class="search" style="margin-top: 100px">
        <v-data-table
          :items="wells_srch"
          :headers="search_headers"
          :key="ksearach"
          :hide-default-header="true"
          :hide-default-footer="Searched_items.length < 10"
          @click:row="search_select"
          :footer-props="{
            'items-per-page-options': [10],
            'disable-items-per-page': true,
            'page-text': '',
          }"
        ></v-data-table>
      </div>
    </div>
    <rapprt
      :well_rapport="rapport_well"
      :dialog="rapport_dialog"
      :key="krapport"
      :corrosion="true"
      @close="rappor_close"
    />
    <waiter :showWait="showWait"> </waiter>
  </div>
</template>

<script>
import CORROSIONS from "../graphql/WellTest/MapCorrosion/MapQuery.gql";
import WELL_RAP from "../graphql/Well/WELL.gql";

//import WELL from "../graphql/WellTest/CCL/WELL.gql";
//import WELLSMAP from "../graphql/Well/WELLSMAP.gql";
export default {
  components: {
    waiter: () => import("../components/Widgets/Waiter.vue"),
    rapprt: () => import("../components/RapportMapping.vue"),
  },
  data() {
    return {
      timer: null,
      player: false,
      years: [],
      currentYear: null,
      rapport_well: {},
      rapport_dialog: false,
      krapport: 10000,
      showWait: false,

      menux: "100px",
      menuy: "100px",
      well: {},
      editer: false,
      valider: false,
      pf: -1000,
      isWellClosed: true,
      wells_types: [],
      wells_status: [],
      fields_list: [],
      suppliers: [],
      components_well: [],
      apicasing: [],
      fluids: [],
      fluids_elements: [],
      agents: [],
      well_tubulars: [],
      well_formations: [],
      well_corrosions: [],
      ksvg: 700,
      col: 0,
      col2: 12,
      Search_state: "d-none",
      Searched: {},
      Searched_items: [],
      ksearach: 800,
      viewlist: true,
      showByIndex: null,
      details: false,
      hideOthers: true,
      severity: [],
      cor1: null,
      cor2: null,
      strings_cor: [],
      last_corrosion: [],
      corrosion_formations: [],
      opr: "1",
      kcor: 0,
      wells_srch: [],
      user_list: [],
      center: { lat: 34.4, lng: 2.78 },
      zoom: 14,
      zoomLabelx: 100,
      zoomLabely: 100,
      FilterScope: "",
      tubulars_list: [],
      isFilterClosed: true,
      isCorrosionClose: true,
      loading: false,

      Test_Corrosion: {},
      corrosion: {},
      formations: [],
      all_tubulars_list: [],
      tool_list: [],
      level_list: [],
      klist: 100,
      cor_key: 500,
      kmap: 100000,
      selitem: {},
      test: {},

      search_headers: [
        {
          text: "Well",
          value: "wellname",
          selected: true,
        },
      ],
      tests_filter: null,
      all_wellformations: [],
      all_wellcorrosion: [],
      all_well: [],
      all_corrosion: [],
      welltype: [],
    };
  },
  mounted() {
    this.changeview();
  },
  created() {
    let currentDate = new Date();
    this.currentYear = currentDate.getFullYear();
    this.getMap();

    //this.filtrer();
  },
  computed: {
    welltypes() {
      return [
        ...new Map(
          this.all_well.map((item) => [
            item["welltype_id"],
            { welltype_id: item.welltype_id, welltype: item.welltype },
          ])
        ).values(),
      ];
    },
  },
  watch: {
    "$store.state.fields": {
      handler() {
        this.klist++;
        this.getMap();
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    startTimer() {
      if (!this.timer) {
        let vi = this;
        vi.player = true;
        let j = vi.years.length;
        this.timer = setInterval(function () {
          let k = vi.years.findIndex((x) => x.testedYear == vi.currentYear);
          if (k >= j - 1) vi.currentYear = vi.years[0].testedYear;
          else vi.currentYear = vi.years[k + 1].testedYear;
          vi.select_year();
        }, 2000);
      }
    },
    pauseTimer() {
      if (this.timer) {
        this.player = false;
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    group(array, key) {
      return [...new Map(array.map((item) => [item[key], item])).values()];
    },
    async rapport_click(pin) {
      let r = await this.$requette(
        WELL_RAP,
        {
          TypeScope: parseInt(pin.well_id),
          CatScope: this.$store.state.fields,
        },
        "no-cache"
      );
      if (r.ok) {
        if (r.data.allwellsstates.length > 0) {
          this.rapport_well = r.data.allwellsstates[0];
          this.rapport_dialog = true;
          this.krapport++;
        }
      }
    },
    rappor_close() {
      this.rapport_dialog = false;
    },

    // @mouseout="showByIndex = null"
    mouseover(event, pin) {
      this.showByIndex = pin;
      //   this.menux = event.domEvent.pageX - 40 + "px";
      //   this.menuy = event.domEvent.clientY - 70 + "px";
    },
    select_year(year) {
      if (year) this.currentYear = year;
      this.find_wells(false);
    },
    zooming() {
      //this.$refs.mapRef.$on('zoom_changed', someFunc)
    },
    closeWellForm(item) {
      this.selitem = item;
      this.isWellClosed = true;
    },

    async changeview() {
      this.$nextTick(() => {
        if (this.$refs.mapRef) {
          this.$refs.mapRef.$mapPromise.then((map) => {
            let x1 = document.querySelector("#dselect");
            map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(
              x1
            );
            let x2 = document.querySelector("#dsearch");
            map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(
              x2
            );
            let x3 = document.querySelector("#dsearch2");
            map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(x3);
          });
        }
      });
    },

    async getMap() {
      this.showWait = true;
      let r = await this.$requette(
        CORROSIONS,
        {
          CatScope: this.$store.state.fields,
        },
        "no-cache"
      );
      if (r) {
        this.formations = r.data.formations_list;
        this.level_list = r.data.level_list;
        this.tubulars_list = r.data.tubulars_list;
        this.all_wellformations = r.data.all_wellformations;
        this.all_wellcorrosion = r.data.all_wellcorrosion;
        // this.wells_srch = r.data.wellsmapall.filter((x) => x.csgs > 0);
        this.wells_srch = this.group(this.all_wellcorrosion, "well_id");
        this.all_well = this.wells_srch;
        this.all_corrosion = this.all_wellcorrosion;
        this.years = this.group(this.all_wellcorrosion, "testedYear").sort(
          (a, b) => b.testedYear - a.testedYear
        );
        r.data.defaults_list.forEach((d) => {
          this.level_list.push({
            id: d.id + 500000,
            label: d.label,
            color: d.color,
            type: "default",
          });
        });
        this.find_wells(true);
      }
      this.showWait = false;
    },

    search_select(item) {
      this.showByIndex = null;

      this.center = { lat: item.lat, lng: item.lng };
      this.zoom = 15;
    },
    search_change() {
      this.find_wells(true);
    },
    //---------------searchFind new

    find_wells(center) {
      this.wells_srch = this.all_well;
      this.all_wellcorrosion = this.all_corrosion;
      let flt = false;
      //level            id=id-500000 when type='default'
      if (this.severity.length > 0) {
        this.all_wellcorrosion = this.all_wellcorrosion.filter((x) =>
          this.severity.find(
            (y) =>
              (y.id == x.level_id && !y.type) ||
              (y.id == x.default_id + 500000 && y.type == "default")
          )
        );
        flt = true;
      }
      //casing
      if (this.strings_cor.length > 0) {
        this.all_wellcorrosion = this.all_wellcorrosion.filter((x) =>
          this.strings_cor.find((y) => y == x.tubular_id)
        );
        flt = true;
      }
      //type
      if (this.welltype > 0) {
        this.all_wellcorrosion = this.all_wellcorrosion.filter((x) =>
          this.welltype.find((y) => y == x.welltype_id)
        );
        flt = true;
      }
      //formations
      if (this.corrosion_formations.length > 0) {
        let f = this.all_wellformations.filter((x) =>
          this.corrosion_formations.includes(x.formation_id)
        );
        this.all_wellcorrosion = this.all_wellcorrosion.filter((x) =>
          f.find(
            (y) =>
              (y.depthfrom <= x.depthfrom && y.depthto >= x.depthfrom) ||
              (x.depthfrom <= y.depthfrom && x.depthto >= y.depthfrom)
          )
        );
        flt = true;
      }
      //year
      this.wells_srch = this.wells_srch.filter(
        (x) => x.testedYear <= this.currentYear
      );
      //wells
      if (flt)
        this.wells_srch = this.wells_srch.filter((x) =>
          this.all_wellcorrosion.find((y) => y.well_id == x.well_id)
        );

      //this.ksearach++;
      if (center)
        if (this.wells_srch.length > 0) {
          this.center = {
            lat: this.wells_srch[0].lat,
            lng: this.wells_srch[0].lng,
          };
        }
    },
    get_label(pin) {
      return (
        "width:70px;color: black; text-align:center;" +
        " font-family: arial;" +
        "padding: 1px;" +
        "background-color: " +
        pin.wtype_color
      );
    },
    get_info(item) {
      let str = [];
      if (this.showByIndex == item) {
        let unit = this.$store.state.options[0].depth;

        for (let i = 1; i < item.csgs + 1; i++) {
          let t = this.all_wellcorrosion
            .filter((x) => x.well_id == item.well_id && x.tubular_id == i)
            .sort((a, b) => b.evaluation - a.evaluation);
          //let k = t.findIndex((x) => x.tubular_id == i);
          if (t.length > 0) {
            let cor = t[0];
            let s = "";
            if (!cor.default_id && cor.evaluation)
              s = cor.evaluation + "% (" + t[0].level + ")";
            else if (cor.default_id) s = cor.label_default;
            else s = "Undefined";
            let cm =
              cor.depthfrom + unit + " to " + cor.depthto + unit + "  -> " + s;

            str.push({
              id: i,
              test: " <h3><b> " + t[0].sizes + ": </b></h3> " + cm + "  </br>",
            });
          }
        }
      }
      return str;
    },
    get_Icon(item) {
      let x = this.get_Icon2(item);
      return x;
    },
    onZoomChanged(event) {
      this.zoom = event;
      // this.kmarkers++;
    },
    get_Icon2(item) {
      let cste = (item.csgs + 1 + this.zoom) * 25 + 35;
      let svg =
        '<svg width="' +
        String(cste) +
        '" height="' +
        String(cste) +
        '" xmlns="http://www.w3.org/2000/svg">';
      let j = 0;
      let h = 30;
      for (let i = item.csgs; i > 0; i--) {
        if (i == item.csgs) h = (i + 1) * 15;
        let color = "gray";
        let t = this.all_wellcorrosion
          .filter((x) => x.well_id == item.well_id && x.tubular_id == i)
          .sort((a, b) => b.evaluation - a.evaluation);

        if (t.length > 0)
          color = t[0].color
            ? t[0].color
            : t[0].default_id
            ? t[0].color_default
            : "gray";
        svg +=
          '<circle r="' +
          String(h - j) +
          '" cy="' +
          String(h + 5) +
          '" cx="' +
          String(h + 5) +
          '" stroke="' +
          color +
          '" fill="white" stroke-width="6" />';
        j = j + 15;
      }
      svg =
        svg +
        '<circle r="15" cy="' +
        String(h + 5) +
        '" cx="' +
        String(h + 5) +
        '" fill="black" stroke="black" stroke-width="20" /></svg>';
      //   let z = 150;
      //   if (this.zoom >= 14 && this.zoom <= 50) z = (this.zoom - 10) * 50;
      //   else if (this.zomm > 50) z = 250;
      this.zoomLabelx = 80;
      this.zoomLabely = 130;
      return {
        url: "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg),
        size: new window.google.maps.Size(
          cste / item.csgs + item.csgs * 3,
          cste / item.csgs + item.csgs * 3
        ),
        anchor: new window.google.maps.Point(25, 25),
        scaledSize: new window.google.maps.Size(20 * this.zoom, 20 * this.zoom),
      };
    },
  },
};
</script>
<style>
#map {
  margin: 0px;
  padding: 0px;
  height: 100vh;
}
.gm-style-iw-chr {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 50px !important;
}

.gm-style-iw.gm-style-iw-c {
  padding-top: 1px !important;
}
.gm-style .gm-style-iw.gm-style-iw-c {
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  overflow: auto;
}

.gm-style .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  overflow: visible !important;
}
/* close button */
.gm-ui-hover-effect {
  display: none !important;
}
/* arrow button */
.gm-style .gm-style-iw-tc::after {
  background: none;
  box-shadow: none;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  appearance: none; /* Pour supprimer le style par défaut du navigateur */
  background-color: white;
}
.select-container {
  position: relative;
  width: 270px;
  background-color: transparent;
}
.search {
  padding: 10px;
  font-size: 14px;
  line-height: 1.4;
  background-color: #fff;
  margin: 10px;
  width: 110px;
}
</style>
